// talkingData统计平台埋点
function setSpot(sendMsg, logMsg, MapKv) {
  try {
    console.log(logMsg)
    window.TDAPP.onEvent(sendMsg, '广告位一', MapKv)
    return true
  } catch (err) {
    console.log(logMsg + '=>err', err)
    return false
  }
}

export default setSpot
