import request from '@/utils/request'

// 获取用户信息
const IndexUrl = 'wx/foreign/getBJYDUser'
export function getToken(query) {
  return request({
    url: IndexUrl,
    method: 'get',
    params: query
  })
}
