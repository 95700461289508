import Vue from 'vue'
import Router from 'vue-router'
import { getLocalStorage, setLocalStorage } from '@/utils/local-storage'

import getUserInfo from '../service/login'

import home from './home'
import items from './items'
import user from './user'
import order from './order'
import login from './login'
import oauth from './oauth'
import activity from './activity'
// import store from '../store/index'

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  // 这个语句用来解决报错
  // 调用原来的push函数，并捕获异常
  return routerPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

const toast = () => {
  if (process.env.VUE_APP_ISAPP === 'true') {
    this.$toast('登录超时, 请返回重试， 若多次失败 请尝试重新登录')
    return true
  }
  return false
}

const RouterModel = new Router({
  mode: 'history',
  routes: [...home, ...items, ...user, ...order, ...login, ...activity, ...oauth]
})

RouterModel.beforeEach(async (to, from, next) => {
  setLocalStorage({ fromRouterPath: from.path })
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.query && to.query.t) {
    try {
      const res = await getUserInfo(to.query.t)
      setLocalStorage({
        Authorization: res.data.token,
        avatar: res.data.usrInfo.avatar,
        nickName: res.data.usrInfo.nickName
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  if (to.query && to.query.replaceToken) {
    setLocalStorage({
      Authorization: to.query.replaceToken
    })
  }

  const { Authorization } = getLocalStorage('Authorization')
  if (to.meta.login) {
    //如果需要登录 往下走
    if (Authorization) {
      //判断是否登录过，如果有登陆过，说明有token,或者token未过期，可以跳过登录（2）
      if (to.path == '/login') {
        //如果已登录去登录页 直接返回首页
        // console.log(to.path)
        // console.log('无法继续操作')
        // return
        if (toast()) {
          return
        }
        next('/index') // 如果是直接跳到首页，
      } else {
        //如果该路由不需要验证，那么直接往后走
        // console.log(to.path)
        next()
      }
    } else {
      if (toast()) {
        return
      }
      RouterModel.replace({
        path: '/login',
        query: { redirect: from.fullPath }
      })
    }
  } else {
    //不需要跳转，直接往下走
    next()
  }

  if (to.matched.length === 0) {
    //如果未匹配到路由
    from.name ? next({ name: from.name }) : next('/') //如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由
  } else {
    next() //如果匹配到正确跳转
  }
})

export default RouterModel
