import { getToken } from 'api/permissions'

function getUserInfo(token) {
  return new Promise((resolve, reject) => {
    getToken({ token: token })
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}

export default getUserInfo
