// 按需全局引入 vant组件
/* eslint-disable */
import Vue from 'vue'
import {
    ActionSheet,
    AddressList,
    Lazyload,
    Icon,
    Cell,
    CellGroup,
    loading,
    Button,
    Toast,
    NavBar,
    Tabbar,
    TabbarItem,
    Dialog,
    SwipeItem,
    Search,
    Panel,
    CouponCell,
    CouponList,
    Card,
    Grid,
    GridItem,
    Row,
    Col,
    Tag,
    List,
    Swipe,
    Tabs,
    Tab,
    Sku,
    GoodsAction,
    GoodsActionButton,
    GoodsActionIcon,
    Popup,
    Field,
    Picker,
    CheckboxGroup,
    SubmitBar,
    checkbox,
    Stepper,
    Uploader,
    Skeleton,
    AddressEdit,
    Step,
    Steps
} from 'vant'

const vantArr = [AddressList, Lazyload, Icon, Cell, CellGroup, loading, Button, Toast, NavBar, Tabbar, TabbarItem, Dialog, SwipeItem, Search, Panel, CouponCell, CouponList, Card, Grid, GridItem, Row, Col, Tag, List, Swipe, Tabs, Tab, Sku, GoodsAction, GoodsActionButton, GoodsActionIcon, Popup, Field, Picker, CheckboxGroup, SubmitBar, checkbox, Stepper, Uploader, Skeleton, AddressEdit, Step, Steps, ActionSheet]

vantArr.forEach((v) => {
    Vue.use(v)
})

Vue.use(Lazyload, {
    preLoad: 1.3,
    error: require('@/assets/images/goods_default.png'),
    loading: require('@/assets/images/goods_default.png'),
    attempt: 1,
    listenEvents: ['scroll'],
    lazyComponent: true
})
