export default [
  {
    path: '/oauth',
    name: 'oauth',
    meta: {
      showHeader: false,
      title: '登录页'
    },
    component: () => import('@/views/login/oauth')
  },
  {
    path: '/code',
    name: 'code',
    meta: {
      showHeader: false,
      title: '券详情'
    },
    component: () => import('@/views/login/code')
  }
  // {
  //   path: '/login',
  //   name: 'login',
  //   meta: {
  //     showHeader: false,
  //     title: '登录页'
  //   },
  //   component: () => import('@/views/login/oauth')
  // }
]
