export default [
  {
    path: '/activity/defense-epidemic',
    name: 'defense-epidemic',
    component: () => import('@/views/activity/defense-epidemic'),
    props: true,
    meta: {
      title: '广州移动防疫专享权益'
    }
  },
  {
    path: '/activity/order/checkout',
    name: 'activity-checkout',
    component: () => import('@/views/activity/order/checkout'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  // 中秋活动
  {
    path: '/activity/mid-autumn',
    name: 'mid-autumn',
    component: () => import('@/views/activity/mid-autumn'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  // 活动详情
  {
    path: '/activity/detail/:id',
    name: 'activity-detail',
    component: () => import('@/views/activity/detail'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  // 升档活动
  {
    path: '/activity/tc-exchange/:code',
    name: 'tc-exchange',
    component: () => import('@/views/activity/tc-exchange'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/tc-detail/:id',
    name: 'activity-tcDetail',
    component: () => import('@/views/activity/detail/tc-detail'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/order/tc-checkout',
    name: 'activity-tcCheckout',
    component: () => import('@/views/activity/order/tc-checkout'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  //健康专区
  {
    path: '/activity/health',
    name: 'health',
    component: () => import('@/views/activity/health'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/health/bugOneGetOne',
    name: 'bugOneGetOne',
    component: () => import('@/views/activity/health/buyOneGetOne'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/health/bannerDetail',
    name: 'bannerDetail',
    component: () => import('@/views/activity/health/bannerDetail'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/health/coupon',
    name: 'health-coupon',
    component: () => import('@/views/activity/health/coupon/health-coupon'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/health/coupon/rule',
    name: 'health-coupon-rule',
    component: () => import('@/views/activity/health/coupon/health-coupon-rule'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/health-detail/:goodID',
    name: 'bugOneGetOne',
    component: () => import('@/views/activity/detail/health-detail'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/order/health-checkout',
    name: 'health-checkout',
    component: () => import('@/views/activity/order/health-checkout'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  //健康专区-双11
  {
    path: '/activity/health/heath_act_11',
    name: 'heath_act_11',
    component: () => import('@/views/activity/heath_act_11'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/health/heath_act_11/rulesDetail',
    name: 'rulesDetail',
    component: () => import('@/views/activity/heath_act_11/rulesDetail'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/order',
    name: 'activity-order',
    component: () => import('@/views/activity/order/activity-order'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/order/heath_act_11-checkout',
    name: 'heath_act_11-checkout',
    component: () => import('@/views/activity/order/heath_act_11-checkout'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  // 双12活动
  {
    path: '/activity/health/heath_act_12',
    name: 'heath_act_12',
    component: () => import('@/views/activity/heath_act_12'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  {
    path: '/activity/health/heath_act_12/rulesDetail',
    name: 'rulesDetail-12',
    component: () => import('@/views/activity/heath_act_12/rulesDetail'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  //空白页
  {
    path: '/activity/wx_blank',
    name: 'heath_act_11-checkout',
    component: () => import('@/views/activity/wx_blank'),
    props: true,
    meta: {
      title: '中国移动健康专题'
    }
  },
  //深圳移动&唯品会品牌月-主页
  {
    path: '/activity/coupon_index',
    name: 'activity-coupon_index',
    component: () => import('@/views/activity/coupon_index/index'),
    meta: {
      title: '移动送权益，上唯品会添新衣'
    },
    props: true
  },
  {
    path: '/activity/coupon_index/401',
    name: 'activity-coupon_index_401',
    component: () => import('@/views/activity/coupon_index/detail/401'),
    meta: {
      title: '年货盲盒权益'
    },
    props: true
  },
  {
    path: '/activity/coupon_index/410',
    name: 'activity-coupon_index_410',
    component: () => import('@/views/activity/coupon_index/detail/410'),
    meta: {
      title: '年货盲盒权益'
    },
    props: true
  },
  {
    path: '/activity/coupon_index/old_use',
    name: 'activity-coupon_index-old_use',
    component: () => import('@/views/activity/coupon_index/old_use'),
    meta: {
      title: '移动送权益，上唯品会添新衣'
    },
    props: true
  },
  {
    path: '/activity/coupon_index/new_use',
    name: 'activity-coupon_index-new_use',
    component: () => import('@/views/activity/coupon_index/new_use'),
    meta: {
      title: '移动送权益，上唯品会添新衣'
    },
    props: true
  },
  {
    path: '/activity/coupon_sms_index',
    name: 'activity-coupon_sms_index',
    component: () => import('@/views/activity/coupon_sms_index'),
    meta: {
      title: '移动送权益，上唯品会添新衣'
    },
    props: true
  },
  {
    path: '/activity/coupon_sms_index/sms_use',
    name: 'activity-coupon_sms_index-sms_use',
    component: () => import('@/views/activity/coupon_index/sms_use'),
    meta: {
      title: '移动送权益，上唯品会添新衣'
    },
    props: true
  },
  //plus活动相关
  {
    path: '/activity/plus',
    name: 'activity-plus',
    component: () => import('@/views/activity/plus'),
    meta: {
      title: '权益领取'
    },
    props: true
  },
  {
    path: '/activity/plus/list',
    name: 'activity-plus-list',
    component: () => import('@/views/activity/plus/list'),
    meta: {
      title: '权益领取'
    },
    props: true
  },
  {
    path: '/activity/plus/detail',
    name: 'activity-plus-detail',
    component: () => import('@/views/activity/plus/detail'),
    meta: {
      title: '权益领取'
    },
    props: true
  },
  // 双旦活动相关
  {
    path: '/activity/new_years',
    name: 'activity-new_years',
    component: () => import('@/views/activity/new_years'),
    meta: {
      title: '年货盲盒权益'
    },
    props: true
  },
  {
    path: '/activity/new_years/agreement',
    name: 'activity-new_years_agreement',
    component: () => import('@/views/activity/new_years/agreement'),
    meta: {
      title: '年货盲盒权益'
    },
    props: true
  },
  {
    path: '/activity/new_years/401',
    name: 'activity-new_years_401',
    component: () => import('@/views/activity/new_years/detail/401'),
    meta: {
      title: '年货盲盒权益'
    },
    props: true
  },
  {
    path: '/activity/new_years/410',
    name: 'activity-new_years_410',
    component: () => import('@/views/activity/new_years/detail/410'),
    meta: {
      title: '年货盲盒权益'
    },
    props: true
  },
  // 元旦活动
  {
    path: '/activity/new_year',
    name: 'activity-new_year',
    component: () => import('@/views/activity/new_year'),
    meta: {
      title: '元旦活动'
    },
    props: true
  },
  //年货节活动
  {
    path: '/activity/spring_festival_purchases',
    name: 'spring_festival_purchases',
    component: () => import('@/views/activity/spring_festival_purchases'),
    meta: {
      title: '年货节活动'
    },
    props: true
  },
  {
    path: '/activity/spring_festival_purchases/401',
    name: 'spring_festival_purchases_401',
    component: () => import('@/views/activity/spring_festival_purchases/detail/401'),
    meta: {
      title: '双旦活动'
    },
    props: true
  },
  {
    path: '/activity/spring_festival_purchases/410',
    name: 'spring_festival_purchases_410',
    component: () => import('@/views/activity/spring_festival_purchases/detail/410'),
    meta: {
      title: '双旦活动'
    },
    props: true
  },
  // 推文
  {
    path: '/activity/tweets',
    name: 'activity_tweets',
    component: () => import('@/views/activity/tweets/index'),
    meta: {
      title: '【低至2折】汤臣倍健全场满减券 单单都返10元话费'
    },
    props: true
  },
  // 分享-测试
  {
    path: '/activity/share_text',
    name: 'activity_share_text',
    component: () => import('@/views/activity/tweets/text'),
    meta: {
      title: '【低至2折】汤臣倍健全场满减券 单单都返10元话费'
    },
    props: true
  }
]
