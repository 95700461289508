import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/iconfont/iconfont.css'
import 'vant/lib/icon/local.css'
import '@/assets/scss/global.scss'
import { Sticky } from 'vant'
import setSpot from './utils/talkingData'

// require('@/utils/crypto-js(2)')
// require('@/utils/jssdk-1.0.0.min')

import VueCountdown from '@chenfengyuan/vue-countdown'
Vue.component(VueCountdown.name, VueCountdown)
Vue.use(Sticky)
// 全局引入按需引入UI库 vant
import '@/plugins/vant'
import 'vant/lib/icon/local.css'

// import a from 'zhongjiayao'

import { RadioGroup, Radio } from 'vant'

Vue.use(Radio)
Vue.use(RadioGroup)

Vue.prototype.$setSpot = setSpot

// Vue.use(a)

// filters
import './filters'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
