/* eslint-disable */
// 正式
module.exports = {
  // title: '时代健康馆',
  title: '中国移动健康专区',
  baseUrl: 'http://test-litemall-h5.timesbigdata.com/#/', // 正式项目地址
  // baseApi: 'http://himall.guochaov.com/', // 正式api请求地址
  baseApi: 'https://gzvip.guochaov.com/',
  isBjApp: false
}
