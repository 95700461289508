<template>
  <div id="app">
    <!-- <v-header></v-header> -->
    <keep-alive>
      <router-view class="view-router" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view class="view-router" v-if="!$route.meta.keepAlive"></router-view>
    <router-view name="tabbar"></router-view>
  </div>
</template>
<script>
// import header from '@/components/Header'
import { SITE_ID } from '@/utils/context'
// import ument_h5 from '@/utils/umeng-h5.js'
// import { SZYD_APPKEY, SD_APPKEY } from '@/utils/context'
export default {
  components: {
    // 'v-header': header
  },
  created() {
    // ument_h5(SZYD_APPKEY)
    // ument_h5(SD_APPKEY)
  },
  mounted() {
    // 友盟统计添加
    const script = document.createElement('script')
    script.src = `https://s9.cnzz.com/z_stat.php?id=${SITE_ID}&web_id=${SITE_ID}`
    script.language = 'JavaScript'
    document.body.appendChild(script)
  },
  watch: {
    $route() {
      console.log('window._czc', window._czc)
      if (window._czc) {
        let location = window.location
        let contentUrl = location.pathname + location.hash
        let refererUrl = '/'
        window._czc.push(['_trackPageview', contentUrl, refererUrl])
      }

      console.log('window', window)
    }
  }
}
</script>
<style lang="scss" src="./assets/scss/global.scss" />
